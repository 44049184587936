.card-container {
  width: 100%;
  height: 20rem;
  background-color: var(--dark-grey);
  border-radius: 3rem;
  padding: 3rem;
  display: grid;
  align-items: center;
  justify-content: center;
}

.svg {
  fill: var(--bright);
  height: 7rem;
  width: auto;
}

.description {
  font-weight: var(--semibold);
  text-align: center;
}

/* For medium screens */
@media screen and (max-width: 920px) {
  .card-container {
    height: 18rem;
    border-radius: 2.5rem;
    padding: 2rem;
  }
}

/* For small screens */
@media screen and (max-width: 576px) {
  .card-container {
    height: 10rem;
    border-radius: 2rem;
    padding: 1rem;
  }

  .svg {
    height: 5rem;
  }

  .fig-text {
    font-size: var(--big);
  }
}

/* For extra small screens */
@media screen and (max-width: 350px) {
  .svg {
    height: 4rem;
  }
}