/* .tech-section {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.tech-container {
  display: grid;
  grid-template-columns: 65% 30%;
  column-gap: 5%;
}

/* .desc-container {
  padding: 1rem;
} */

.desc {
  font-size: var(--h2);
  font-weight: var(--bold);
  text-align: justify;
  /* line-height: var(--h1); */
}

.svg-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-img {
  fill: var(--bright);
  width: 100%;
  height: auto;
}

/* For medium screens */
@media screen and (max-width: 920px) {
  .tech-container {
    display: grid;
    grid-template-columns: 75% 20%;
    column-gap: 5%;
  }
}

/* For small screens */
@media screen and (max-width: 576px) {
  .svg-container {
    opacity: 0;
    display: none;
  }

  .tech-container {
    padding-top: 0;
    display: block;
  }

  .desc {
    font-size: var(--h3);
    line-height: var(--h2);
  }
}

/* For extra small screens */
@media screen and (max-width: 350px) {
}