/* GOOGLE FONTS  */
@import url('https://fonts.googleapis.com/css2?family=Spline+Sans+Mono:wght@300;400;500;600;700&family=Unica+One&display=swap');

/* CSS VARIABLES */
:root {

  /* COLORS */
  --dark: #000000;
  --bright: #f5f3f4;
  --dark-grey: #0e0e0e;
  --hot-red: #ba181b;
  --light-grey: #d3d3d3;

  /* GRADIENTS */
  --wgrad: linear-gradient(135deg, #d3d3d3, #f5f3f4);
  --dgrad: linear-gradient(135deg, #0d0d0d, #262626);

  /* FONTS */
  --ffh: 'Unica One', cursive;
  --ffc: 'Spline Sans Mono', monospace;

  /* FONT SIZES */
  /* 1rem = 16px */
  --big: 4rem;
  --h1: 3rem;
  --h2: 2rem;
  --h3: 1.5rem;
  --normal: 1rem;
  --small: 0.875rem;
  --smaller: 0.813rem;
  --tiny: 0.625rem;

  /* FONT WEIGHTS */
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;

  /* MARIGINS */
  --m-025: 0.25 rem;
  --m-05: 0.5rem;
  --m-075: 0.75rem;
  --m-1: 1rem;
  --m-15: 1.5rem;
  --m-2: 2rem;
  --m-25: 2.5rem;
  --m-3: 3rem;
  --m-4: 4rem;
  --m-6: 6rem;
  --m-8: 8rem;
  --m-10: 10rem;
  --m-12: 10rem;

  /* Z-INDEX */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;

  /* TRANSITION */
  --transition: all 400ms ease-in-out;
}

/* BASE */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body, button, input, textarea {
  font-family: var(--ffc);
  font-size: var(--normal);
}

body {
  background-color: var(--dark);
  color: var(--bright);
}

h1, h2, h3 {
  color: var(--bright);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* REUSABLE CSS CLASSES */
.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 0 2rem 0;
}

.section_title {
  font-size: var(--h1)
}

.section_subtitle {
  display: block;
  font-size: var(--small);
  margin-bottom: 4rem;
}

/* LAYOUT */
.head-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 8rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.heading {
  font-size: var(--h1);
  font-family: var(--ffh);
  font-weight: var(--light);
  padding: 1rem 0;
  color: var(--bright);
}

.content {
  font-size: var(--normal);
  font-family: var(--ffc);
  font-weight: var(--regular);
  line-height: var(--h3);
}

.center-h {
  display: flex;
  justify-content: center;
}

.center-txt-h {
  text-align: center;
}

.btn  {
  font-size: var(--small);
  font-weight: var(--semibold);
  background-color: var(--bright);
  color: var(--dark-grey);
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.cta {
  background-color: var(--hot-red);
  color: var(--bright);
}

.logo {
  fill: var(--bright);
  width: 5rem;
  height: auto;
}

.icon {
  fill: var(--bright);
  width: 2rem;
  height: auto;
}

/* BREAKPOINTS */
/* For Larger screens */
/* @media screen and (max-width: 1080px) {
  .container {
    margin-left: var(--m-25);
    margin-right: var(--m-25);
  }
} */

/* For medium screens */
@media screen and (max-width: 920px) {
  :root {
    /* FONT SIZES */
    /* 1rem = 16px */
    --big: 3.5rem;
    --h1: 2.5rem;
    --h2: 1.5rem;
    --h3: 1rem;
    --normal: 0.875rem;
    --small: 0.75rem;
    --smaller: 0.625rem;
    --tiny: 0.5rem;
  }

  .section {
    padding-bottom: 0;
  }

  .container {
    padding: 2rem 4rem;
  }
}

/* For small screens */
@media screen and (max-width: 576px) {
  :root {
    /* FONT SIZES */
    /* 1rem = 16px */
    --big: 3rem;
    --h1: 2.25rem;
  }

  .section {
    padding-top: 4rem;
  }

  .container {
    padding: 0 2rem;
  }

  .logo {
    width: 3rem;
    height: auto;
  }

  .icon {
    fill: var(--bright);
    width: 1.5rem;
    height: auto;
  }
}

/* For extra small screens */
@media screen and (max-width: 350px) {
  :root {
    /* FONT SIZES */
    /* 1rem = 16px */
    --big: 2.5rem;
    --h1: 1.75rem;
    --h2: 1.25rem;
    --h3: 0.875rem;
    --normal: 0.75rem;
    --small: 0.625rem;
    --smaller: 0.5rem;
    --tiny: 0.375rem;
  }

  .section {
    padding-top: 2rem;
    padding-bottom: 0;
  }

  .head-container {
    padding: 0 1rem;
  }

  .container {
    padding: 2rem 1rem 0 1rem;
  }
}