.shapes-container {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.shapes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nipple {
  fill: var(--light-grey);
  width: 100%;
  height: auto;
}

/* For medium screens */
@media screen and (max-width: 920px) {
  .shapes-container {
    top: 6rem;
    height: auto;
    margin: 6rem 0;
  }
}

/* For small screens */
@media screen and (max-width: 576px) {
  .shapes-container {
    top: 6rem;
    height: auto;
    margin: 2rem 0;
  }
}