/* .about-section {
  height: 100vh;
  width: 100%;
}

.about-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 12rem;
} */

.punch-line {
  max-width: 30rem;
  margin: 0 auto;
}

.tag-line {
  margin: 1rem 0 0 0;
  line-height: var(--h2);
}

.btns-stack {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  text-align: center;
}

/* For medium screens */
@media screen and (max-width: 920px) {
  .about-container {
    margin-top: 4rem;
  }
}

/* For small screens */
@media screen and (max-width: 576px) {
  .about-container {
    margin-top: 6rem;
  }

  .btns-stack {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
}