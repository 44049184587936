.service-section {
  min-height: 100vh;
  width: 100%;
}

.service-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.svg-star-container {
  position: relative;
  left: 0;
  top: 0;
  z-index: 0;
}

.svg-star {
  fill: var(--bright);
  height: 6rem;
  width: auto;
}

.services-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6rem;
}

.service-head {
  font-size: 1.25rem;
  font-weight: var(--semibold);
  margin: 3rem 0 1rem 0;
}

.service-desc {
  line-height: 1.5rem;
}

/* For medium screens */
@media screen and (max-width: 920px) {
  .service-section {
    height: 100%;
  }

  .service-container {
    margin-bottom: 6rem;
  }
}


/* For small screens */
@media screen and (max-width: 576px) {
  .services-list {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
  }

  .service-head {
    margin-top: 2rem;
  }

  .svg-star {
    fill: var(--bright);
    height: 4rem;
    width: auto;
  }
}

/* For extra small screens */
@media screen and (max-width: 350px) {
  .service-head {
    margin-top: 1rem;
  }
}
