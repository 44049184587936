.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed)
}

.nav {
  position: relative;
  height: 6rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav_logo {
  color: var(--light-grey);
  font-size: var(--h2);
  font-weight: var(--semibold);
}

.nav_menu {
  margin: 0 0 0 auto;
}

.nav_list {
  display: flex;
  column-gap: 2rem;
  list-style-type: none;
}

.nav_item {
  align-content: center;
}

.nav_link_container {
  transition: var(--transition);
  padding: var(--m-05) 0 0 var(--m-2);
  opacity: 1.0;
}

.nav_list:hover .nav_item:not(:hover) .nav_link_container {
  opacity: 0;
}

.nav_link {
  color: var(--light-grey);
  font-weight: var(--medium);
}

/* .nav_link_container:hover {
  background-color: var(--bright);
  border: 1px solid #ffffff;
}

.nav_link_container:hover a {
  color: var(--dark-grey);
} */

/* BREAKPOINTS */
/* For small screens */
@media screen and (max-width: 576px) {
  .nav_menu {
    opacity: 0;
    display: none;
  }

  .nav_logo {
    font-size: var(--normal);
  }
}