.footer-container {
  margin: 0rem 0 6rem 0;
}

.social-container {
  margin: 2rem 0;
}

.social-list {
  display: flex;
  column-gap: 1rem;
}

.footer-logo {
  fill: var(--hot-red);
}