.filler-section {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/astronaut.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  background-color: var(--dark-grey);
  opacity: 0.7;
}

.filler-container {
  display: block;
  z-index: 10;
}

/* For medium screens */
@media screen and (max-width: 920px) {
  /* .text-quote {
    max-width: 50rem;
  } */
}